import React from 'react';
import { Grid, Button, Typography } from '@mui/material';

const HoleSelectionGrid = ({ holes, onHoleSelect }) => {
  return (
    <div>
      <Typography variant="h6" gutterBottom>Select a Hole</Typography>
      <Grid container spacing={2}>
        {holes.map((hole) => (
          <Grid item xs={3} sm={2} key={hole.hole_number}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => onHoleSelect(hole.hole_number)}
            >
              Hole {hole.hole_number}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default HoleSelectionGrid;

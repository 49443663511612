import React from 'react';
import { AppBar, Toolbar as MuiToolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AppContext';
import { logout } from '../services/apiService';

const StyledToolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
}));

const Logo = styled('img')({
  height: '50px',
});

const Toolbar = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout API
      setUser(null); // Clear the user from the global state
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.error('Logout failed:', error);
      // TODO: Show error message to user
    }
  };

  return (
    <AppBar position="static" color="default" elevation={1}>
      <StyledToolbar>
        <Logo src={`${process.env.PUBLIC_URL}/pln_black_transparent_logo.png`} alt="PLN Logo" />
        <Typography variant="h6" component="div">
          PLN Scoring
        </Typography>
        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>
      </StyledToolbar>
    </AppBar>
  );
};

export default Toolbar;
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import { fetchLiveScoresData } from '../services/apiService';
import { useTournamentSelection, useLoadingError } from '../AppContext';

const SportsSelectionPage = () => {
  const { tournaments, selectedEvent, setTournaments, setEvent } = useTournamentSelection();
  const { loading, error, setLoading, setError } = useLoadingError();
  const [selectedTournamentId, setSelectedTournamentId] = useState('');
  const navigate = useNavigate();

  const latestSetLoading = useRef(setLoading);
  const latestSetError = useRef(setError);
  const latestSetTournaments = useRef(setTournaments);

  useEffect(() => {
    latestSetLoading.current = setLoading;
    latestSetError.current = setError;
    latestSetTournaments.current = setTournaments;
  });

  const loadLiveScoringData = useCallback(async () => {
    try {
      latestSetLoading.current(true);
      const fetchedData = await fetchLiveScoresData();
      if (fetchedData && fetchedData.length > 0) {
        latestSetTournaments.current(fetchedData);
      } else {
        latestSetError.current('No tournaments available');
      }
    } catch (err) {
      latestSetError.current('Failed to load live scoring data. Please try again.');
    } finally {
      latestSetLoading.current(false);
    }
  }, []);

  useEffect(() => {
    loadLiveScoringData();
  }, [loadLiveScoringData]);

  const handleTournamentChange = (e) => {
    const tournamentId = e.target.value;
    setSelectedTournamentId(tournamentId);
    setEvent(null);
  };

  const handleEventChange = (e) => {
    setEvent(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedTournamentId && selectedEvent) {
      navigate(`/scoring/${selectedTournamentId}/${selectedEvent}`);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const selectedTournament = tournaments?.find(t => t.tournament_id === selectedTournamentId);

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Select Tournament and Event
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="tournament-select-label">Select a tournament</InputLabel>
            <Select
              labelId="tournament-select-label"
              id="tournament-select"
              value={selectedTournamentId}
              label="Select a tournament"
              onChange={handleTournamentChange}
            >
              <MenuItem value="">
                <em>Select a tournament</em>
              </MenuItem>
              {tournaments && tournaments.map((tournament) => (
                <MenuItem key={tournament.tournament_id} value={tournament.tournament_id}>
                  {tournament.tournament_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          {selectedTournament && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="event-select-label">Select an event</InputLabel>
              <Select
                labelId="event-select-label"
                id="event-select"
                value={selectedEvent || ''}
                label="Select an event"
                onChange={handleEventChange}
              >
                <MenuItem value="">
                  <em>Select an event</em>
                </MenuItem>
                {selectedTournament.events.map((event) => (
                  <MenuItem key={event.event_id} value={event.event_id}>
                    {event.event_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!selectedEvent}
          >
            Proceed to Scoring
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SportsSelectionPage;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AppProvider } from './AppContext';
import LoginForm from './components/LoginForm';
import SportsSelectionPage from './components/SportsSelectionPage';
import TournamentScoringContainer from './components/TournamentScoringContainer';
import Toolbar from './components/Toolbar';
import { useAuth } from './AppContext';

const AppContent = () => {
	const { isAuthenticated } = useAuth();

	return (
		<>
			{isAuthenticated && <Toolbar />}
			<Routes>
				<Route path="/login" element={isAuthenticated ? <Navigate to="/select-sport" /> : <LoginForm />} />
				<Route path="/select-sport" element={isAuthenticated ? <SportsSelectionPage /> : <Navigate to="/login" />} />
				<Route path="/scoring/:tournamentId/:eventId" element={isAuthenticated ? <TournamentScoringContainer /> : <Navigate to="/login" />} />
				<Route path="/" element={<Navigate to="/login" />} />
			</Routes>
		</>
	);
};

const App = () => {
	return (
		<AppProvider>
			<Router>
				<AppContent />
			</Router>
		</AppProvider>
	);
};

export default App;

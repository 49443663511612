import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import { fetchLiveScoresData } from '../services/apiService';
import HoleSelectionGrid from './HoleSelectionGrid';
import PlayerScoringCard from './PlayerScoringCard';

const TournamentScoringContainer = () => {
  const { tournamentId, eventId } = useParams();
  const [eventData, setEventData] = useState(null);
  const [selectedHole, setSelectedHole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchLiveScoresData();
        const tournament = data.find(t => t.tournament_id === tournamentId);
        if (tournament) {
          const event = tournament.events.find(e => e.event_id === eventId);
          if (event) {
            setEventData(event);
          } else {
            setError('Event not found');
          }
        } else {
          setError('Tournament not found');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch tournament data');
        setLoading(false);
      }
    };
    fetchData();
  }, [tournamentId, eventId]);

  const handleHoleSelect = (holeNumber) => {
    setSelectedHole(holeNumber);
  };

  const handleScoreUpdate = (playerId, holeNumber, score) => {
    console.log(`Updating score for player ${playerId}, hole ${holeNumber}: ${score}`);
    // TODO: Implement score update logic
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!eventData) return null;

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {eventData.event_name || 'Event Scoring'}
        </Typography>
        {!selectedHole ? (
          <HoleSelectionGrid 
            holes={eventData.sport_details.regulation_scoring[0].holes} 
            onHoleSelect={handleHoleSelect} 
          />
        ) : (
          <PlayerScoringCard 
            holeNumber={selectedHole}
            players={eventData.sport_details.regulation_scoring}
            onScoreUpdate={handleScoreUpdate}
            onBack={() => setSelectedHole(null)}
          />
        )}
      </Box>
    </Container>
  );
};

export default TournamentScoringContainer;

import axios from 'axios';

const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,    
});

// Add a request interceptor
apiInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            console.log('Token added to request:', token);
        } else {
            console.log('No token found in localStorage');
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//The following is the main function call, the rest are me testing 
export const fetchLiveScoresData = async () => { 
    try {
        const response = await apiInstance.get('/api/liveScores/');
        console.log('Fetched liveScores Data:', response.data);
        return response.data;
    } catch (err) {
        console.error('Error fetching liveScores Data:', err);
        throw err;
    }
};

export const login = async (credentials) => {
    try {
        const response = await apiInstance.post('/api/auth/login', {
            EmailOrUserName: credentials.EmailOrUserName,
            Password: credentials.Password
        });
        console.log('Login response:', response.data); // Added for debugging

        const tokenData = response.data[0];
        const { access_token: token } = tokenData;

        if (token) {
            localStorage.setItem('token', token);
            return token;
        } else {
            console.error('No token in response:', response.data);
            throw new Error('No token received from server');
        }
    } catch (err) {
        console.error('Login error:', err.response ? err.response.data : err.message);
        throw err;
    }
};

export const logout = () => {
    localStorage.removeItem('token');
};

// export const fetchEventDetails = async (eventId) => {
//     try {
//         console.log('Fetching event details for eventId:', eventId);
//         const response = await apiInstance.get(`/api/events/${eventId}`);
//         console.log('Event details response:', response.data);
//         return response.data;
//     } catch (err) {
//         console.error('Error fetching event details:', err);
//         if (err.response) {
//             console.error('Error response:', err.response.data);
//             console.error('Error status:', err.response.status);
//             console.error('Error headers:', err.response.headers);
//         } else if (err.request) {
//             console.error('No response received:', err.request);
//         } else {
//             console.error('Error setting up request:', err.message);
//         }
//         throw err;
//     }
// };

// export const fetchLiveScores = async (tournamentId) => {
//     try {
//         console.log('Fetching live scores for tournamentId:', tournamentId);
//         const response = await apiInstance.get(`/api/liveScores/${tournamentId}`);
//         console.log('Live scores response:', response.data);
//         return response.data;
//     } catch (err) {
//         console.error('Error fetching live scores:', err);
//         throw err;
//     }
// };

// export const fetchTournaments = async () => {
//     try {
//         const response = await apiInstance.get('/api/tournaments/');
//         console.log('Fetched tournaments:', response.data);
//         return response.data;
//     } catch (err) {
//         console.error('Error fetching tournaments:', err);
//         throw err;
//     }
// };

// export const fetchTournamentDetails = async (tournamentId) => {
//     try {
//         const response = await apiInstance.get(`/api/tournaments/${tournamentId}`);
//         console.log('Fetched tournament details:', response.data);
//         return response.data;
//     } catch (err) {
//         console.error('Error fetching tournament details:', err);
//         throw err;
//     }
// };

export default apiInstance;

import React from 'react';
import { Typography, Button, TextField, Box } from '@mui/material';
import { useScoring } from '../AppContext';

const PlayerScoringCard = ({ holeNumber, players, onBack, onScoreUpdate }) => {
  const { scores, updateScore } = useScoring();

  const handleScoreUpdate = (playerId, score) => {
    updateScore(holeNumber, playerId, score);
    onScoreUpdate(playerId, holeNumber, score); // Call the passed function
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>Hole {holeNumber} Scoring</Typography>
      {players.map((player) => (
        <Box key={player.player_id} sx={{ mb: 2 }}>
          <Typography variant="subtitle1">{player.player_name}</Typography>
          <TextField
            type="number"
            label="Score"
            value={scores[holeNumber]?.[player.player_id] || ''}
            onChange={(e) => handleScoreUpdate(player.player_id, e.target.value)}
            sx={{ mr: 2 }}
          />
        </Box>
      ))}
      <Button variant="contained" onClick={onBack} sx={{ mt: 2 }}>
        Back to Hole Selection
      </Button>
    </div>
  );
};

export default PlayerScoringCard;

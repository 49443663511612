import React, { createContext, useReducer, useContext } from 'react';

// Create a context
const AppContext = createContext();

// Initial state
const initialState = {
  user: null,
  tournaments: [],
  selectedEvent: null,
  scores: {},
  loading: false,
  error: null
};

// Reducer function
function appReducer(state, action) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_TOURNAMENTS':
      return { ...state, tournaments: action.payload };
    case 'SET_EVENT':
      return { ...state, selectedEvent: action.payload };
    case 'UPDATE_SCORE':
      return {
        ...state,
        scores: {
          ...state.scores,
          [action.payload.holeNumber]: {
            ...state.scores[action.payload.holeNumber],
            [action.payload.playerId]: action.payload.score
          }
        }
      };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

// Provider component
export function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

// Custom hook to use AppContext
export function useAppContext() {
  return useContext(AppContext);
}

//Custom hooks
export function useAuth() {
  const { state, dispatch } = useAppContext();
  return {
    user: state.user,
    setUser: (user) => dispatch({ type: 'SET_USER', payload: user }),
    isAuthenticated: !!state.user
  };
}

export function useTournamentSelection() {
  const { state, dispatch } = useAppContext();
  return {
    tournaments: state.tournaments,
    selectedEvent: state.selectedEvent,
    setTournaments: (tournaments) => dispatch({ type: 'SET_TOURNAMENTS', payload: tournaments }),
    setEvent: (event) => dispatch({ type: 'SET_EVENT', payload: event })
  };
}

export function useScoring() {
  const { state, dispatch } = useAppContext();
  return {
    scores: state.scores,
    updateScore: (holeNumber, playerId, score) => 
      dispatch({ type: 'UPDATE_SCORE', payload: { holeNumber, playerId, score } })
  };
}

export function useLoadingError() {
  const { state, dispatch } = useAppContext();
  return {
    loading: state.loading,
    error: state.error,
    setLoading: (isLoading) => dispatch({ type: 'SET_LOADING', payload: isLoading }),
    setError: (error) => dispatch({ type: 'SET_ERROR', payload: error })
  };
}
